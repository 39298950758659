export default function () {
    const sitePolicyArticles = [
        {
            article:
                '大自然界WEBサイト（以下、「本サイト」といいます）は、大自然界が運営しております。本サイトをご利用の際には、以下の利用条件をよくお読みいただき、ご同意の上、本サイトをご利用下さいますようお願い申し上げます。本サイトをご利用いただいた場合には、全ての利用条件にご同意いただいたものとさせていただきますので、ご了承下さい。\n \
                本条の規約に違反して問題が発生した場合、利用者は、自己の責任と費用においてかかる問題を自分で解決するとともに、大自然界に何らの迷惑並びに損害を与えないものとします。\n \
                なお、大自然界は本サイトの利用条件等を必要に応じて変更する事がございます。本サイトのご利用にあたっては、その都度内容をご確認下さいますようお願い申し上げます。',
        },
        {
            title: '著作権について',
            article:
                '<ul class="num-list-ul">\
                    <li class="num-list-li">本サイトの著作権は大自然界に帰属しています。本サイトに掲載している文章・動画・写真・図・イラスト等の各々の情報及び各ページは、著作権法の保護の対象となる著作物であり、本サイトの全体についても編集著作物として著作権の対象となっています。</li>\
                    <li class="num-list-li">利用者は本サイトの内容について、「私的使用のための複製」や「引用」など著作権法上認められた場合を除き、無断で複製・転載・加工・転用、販売、出版、その他これに類する用途に使用する事はできません。本サイトに掲載されている内容を著作権法に規定される範囲で引用する場合には、必ず大自然界のWEBサイトである事を明記して下さいますよう、お願いいたします。</li>\
                    <li class="num-list-li">利用者は、大自然界の承諾を得ないで、いかなる方法においても、第三者として、本サイトを通じて提供されるいかなる情報も使用させたり公開させたりする事は出来ないものとします。</li>\
                    <li class="num-list-li">大自然界が利用者に発信したメール等についての著作権は大自然界が所有しており、無断で他のサイトや印刷物等へ複製・転載・加工・転用、その他これに類する行為の対象とする事はできません。</li>\
                </ul>',
        },
        {
            title: '利用者の地位および制限・禁止事項',
            article:
                '利用者は、以下に掲げる行為は行ってはならないものとします。\
                <ul class="num-list-ul">\
                    <li class="num-list-li">大自然界（あるいは界員）もしくは第三者の財産もしくはプライバシー等を侵害する行為、あるいは侵害する恐れのある行為</li>\
                    <li class="num-list-li">大自然界もしくは第三者に、不利益もしくは損害を与える行為、またはその恐れのある行為</li>\
                    <li class="num-list-li">本サイトの運営を妨害する行為、信用を毀損する行為</li>\
                    <li class="num-list-li">ログインパスワードなどを第三者に開示、貸与または譲渡する行為</li>\
                    <li class="num-list-li">公序良俗に反する行為</li>\
                    <li class="num-list-li">犯罪行為もしくは犯罪行為に結びつく行為、またはその恐れのある行為</li>\
                    <li class="num-list-li">虚偽の申告、届け出を行う行為</li>\
                    <li class="num-list-li">本サイトを利用した営業活動もしくは営利を目的とする行為、またはその準備を目的とする行為。ただし、大自然界が別途承認した場合には、この限りではありません。</li>\
                    <li class="num-list-li">大自然界（あるいは界員）もしくは第三者の名誉もしくは信用を毀損する行為</li>\
                    <li class="num-list-li">コンピュータウィルスなど有害なプログラムを使用もしくは提供する行為、またはその恐れのある行為</li>\
                    <li class="num-list-li">その他、国内外の法律・法令に違反する行為、またはその恐れのある行為</li>\
                    <li class="num-list-li">その他、大自然界が不適切と判断する行為</li>\
                </ul>\
                上記、禁止事項に該当する行為が行われたことを確認した場合、大自然界は、その行為を行った利用者に対し、大自然界の全サービスの利用停止、契約の解除、除名といった対処を行えるものとします。また大自然界は当該利用者に対し、その後の大自然界サービスへの新規申し込みを全て拒否する権利を有します。',
        },
        {
            title: 'IDおよびパスワード等の管理',
            article:
                '<ul class="num-list-ul">\
                    <li class="num-list-li">IDやパスワード等の管理については、利用者の責任の下で行わなければならない事とします。また利用者が第三者に開示、貸与または譲渡する行為は行ってはならないものとします。</li>\
                    <li class="num-list-li">IDやパスワードを不正使用される可能性がある場合には、利用者は速やかに大自然界に連絡する義務を負うものとします。大自然界が利用者から連絡を受けた後に、大自然界内で調査の上、利用者へログインパスワード等の再発行を行います。</li>\
                    <li class="num-list-li">IDやパスワードの不正利用により生じた利用者および第三者への損害等については、大自然界は一切の責任を負わないものとします。</li>\
                </ul>',
        },
        {
            title: '本サイトへのリンク',
            article:
                '<ul class="num-list-ul">\
                    <li class="num-list-li">本サイトへのリンクは原則として自由ですが、リンクを設定する際には必ず大自然界のWEBサイトである事を明記して下さいますよう、お願いいたします。また、別ウィンドウで開くようにし、リンク元のページと区別出来るようにして下さい。</li>\
                    <li class="num-list-li">本サイトへのリンクの許可依頼およびリンクの連絡は不要です。ただし、公序良俗に反する内容、大自然界を誹謗中傷する内容を含むサイト等、大自然界がリンクを不適切と判断するサイトが発覚した場合、リンクをお断りする事がございます。</li>\
                    <li class="num-list-li">大自然界は、本サイトへのリンクが設置された第三者のサイトに対して、本サイトへのリンクにより発生したいかなる問題・損害・損失等についても、一切の責任を負いません。</li>\
                    <li class="num-list-li">大自然界では、本サイトのご利用に起因し、ソフトウェア、ハードウェア上に生じた事故、コンピューターウィルスによる汚染、データの減失・毀損等の一切の問題・損害・損失等、および利用者と第三者間において生じたトラブル等による問題・損害・損失等について、いかなる責任も負いかねますので予めご了承下さい。 </li>\
                </ul>',
        },
        {
            title: '本サイトからのリンク',
            article:
                '本サイトには利用者の便宜のために他のサイトへのリンクが設定されていますが、大自然界は、これらの第三者のサイトの利用に伴う問題・損害・損失等について、いかなる責任も負いません。',
        },
        {
            title: '免責事項',
            article:
                '大自然界では本サイトに記載する情報について、細心の注意を払って検討・確認して掲載しておりますが、誤植や不完全な記述が含まれる場合があります。大自然界は、利用者が本サイトの情報をご利用し、もしくはご利用されなかった事で生じる問題・損害・損失等について、いかなる場合でも一切の責任を負いません。\n \
                本サイトは予告なしに内容およびアドレスを変更・削除する場合があります。あらかじめご了承下さい。 ',
        },
    ]
    return { sitePolicyArticles }
}
